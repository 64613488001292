export default {
  data() {
    return {
      errors: {},
    };
  },

  methods: {
    /**
     * Errors handling for edition page.
     * Redirect to the right page if an error in trigger
     *
     * @param {Error} e
     * @param {String} entity - Entity's name which has triggered the error
     */
    handleErrors(e, entity = null, withNotification = false) {
      $console.error(e);
      let status = this.$basil.get(e, 'response.status', e.status);

      if(!this.$basil.isNil(status)) {
        switch(status) {
          case 404:
            if(entity) {
              this.$router.replace({ name: 'item-notfound', params: { entity } }).catch((e) => {})
            }
            this.errors = this.$basil.get(e, '_items')
            break;

          case 422:
            this.errors = this.$basil.get(e, '_items');

            if(withNotification) {
              this.$notification({
                title: this.$t('sayl.notif_error_422_title'),
                message: this.$t('sayl.notif_error_422_message'),
                type: 'error'
              });
            }
          break;

          case 500:
            this.$router.replace({ name: 'server-error', params: { entity } })
            break;
        }
      }

      return this.errors
    },

    /**
     * Translate the errors
     *
     * @param {String} error
     */
    translateError(error) {
      if(!error.length){
        return;
      }

      return error.map(err => {
        return err.includes('_') ? this.$t(`validation.${err}`) : err;
      });
    },

    /**
     * Get an error by fields name if present
     *
     * @param {String} name - Name of the field
     */
    getErrors(name) {
      let ret = [];
      if(this.errors != null && Object.keys(this.errors).length > 0) {
        if(this.errors.status && this.errors.status === 422 && this.errors.get) {
          return this.translateError(this.errors.get(name));
        }
        else if(this.errors.hasOwnProperty('items') && this.errors.items.hasOwnProperty(name)) {
          return this.translateError(this.errors.items[name])
        }
        else if(this.errors.hasOwnProperty(name)) {
          return this.translateError(this.errors[name]);
        }
      }
      return ret;
    },

    /**
     * Remove the error if present
     *
     * @param {String} name
     */
    onRemoveError(name) {
      // this.errors !== null && this.errors.remove(name);
      if(!this.$basil.isNil(this.errors[name])) {
        delete this.errors[name];
      }

      if(this.errors.hasOwnProperty('_items') && this.errors._items[name]) {
        delete this.errors._items[name];
      }

      if(this.errors.hasOwnProperty('items') && this.errors.items[name]) {
        delete this.errors.items[name];
      }
    },

    /**
     * Array of Errors
     *
     * @param {Array} errors
     */
    setErrors(errors) {
      this.errors = errors;
    },
  }
}
