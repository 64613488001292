import { isValidPhoneNumber } from "libphonenumber-js"

export default {
  methods: {
    validatePhoneNumber(phoneNumber) {
      if(phoneNumber && isValidPhoneNumber(phoneNumber)) return true

      return false
    }
  }
}
