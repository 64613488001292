<script>
import ErrorsMixin from '@/helpers/errors'
import PhoneMixin from '@/mixins/phone'

export default {
  name: 'ViewNft',

  mixins: [
    ErrorsMixin,
    PhoneMixin
  ],

  data() {
    return {
      loading: false,
      brand: null,
      nft: null,
      isExternal: false,
      actionLoader: false,
      selectedAdvantageTab: null,

      isTransfering: false,
      destination: '',
      showTransferModal: false,
      claimId: null,
      transferId: null,
    }
  },

  computed: {
    address() {
      let address = this.nft?.address

      if(!address) return

      if(address.length <= 15) {
        return address
      }

      return `${address.substring(0, 7)}...${address.substring(address.length - 5)}`
    },

    hasDescription() {
      return !!this.nft?.saylNft?.description?.all
    },

    hasBanner() {
      return !!this.brand?.banner
    },

    hasLogo() {
      return !!this.brand?.logo
    },

    hasAdvantages() {
      return this.web3Vouchers.length || this.perks.length
    },

    id() {
      return this.$route.params.id
    },

    isValidDestination() {
      /*
        1/ phone
        2/ hedera address
          => https://docs.hedera.com/guides/core-concepts/accounts#account-id
          => https://hashgraph.github.io/hedera-protobufs/#proto.AccountID
        3/ phone number
      */
      let conds = [
        /^[\w-\+\.\_]+(\.[\w-\+\.\_]+)*@[\w-\+\.\_]+(\.[\w\+\.\_]+)*(\.[A-Za-z]{2,})$/.test(this.destination),
        /^[0-9]+\.[0-9]+\.[0-9]+$/.test(this.destination),
        // this.validatePhoneNumber(this.destination)
      ]

      return conds.find(c => c)
    },

    messages() {
      return this.$messaging.overview.find(o => o.nft.id === this.id) ?? {}
    },

    mode() {
      return this.$route.meta?.mode ?? 'view'
    },

    nftChain() {
      return this.nft?.chain?.name
    },

    nftDescription() {
      return this.nft?.saylNft?.description?.all
    },

    nftExplorerLink() {
      return this.nft?.explorerLink
    },

    nftImageType() {
      return this.nft?.imageType
    },

    nftImageUrl() {
      return this.nft?.imageUrl
    },

    nftName() {
      return this.nft?.name
    },

    nftSubtitle() {
      return this.nft?.saylAppBrand?.name
    },

    nftTokenId() {
      return this.nft?.tokenId
    },

    perks() {
      return this.nft?.web3Triggers ?? []
    },

    vouchers() {
      return this.nft?.vouchers ?? []
    },

    web3Vouchers() {
      return this.nft?.web3Vouchers ?? []
    },
  },

  methods: {
    async findNft() {
      for(let walletId in this.$wallet.wallets) {
        let nft = this.$wallet.wallets[walletId].nfts?.find(n => n.id === this.id)

        if(nft) {
          this.nft = nft

          if(this.web3Vouchers.length) {
            this.selectedAdvantageTab = 'vouchers'
          } else if(this.perks.length) {
            this.selectedAdvantageTab = 'perks'
          }

          if(walletId !== 'sayl') {
            this.isExternal = true
          }

          this.brand = await this.$brands.getBrand({ id: this.nft.saylAppBrand?.id })

          this.$messaging.getOverview()
          break
        }
      }

      return
    },

    goToMessages() {
      this.$router.push({ name: 'sayl-connect_messaging-details', params: { id: this.nft.id } })
    },

    goToVouchers() {
      this.$router.push({ name: 'sayl-connect_vouchers-vouchers', query: { tab: 'web3', id: this.nft.id } })
    },

    async initClaim() {
      try {
        this.loading = true

        const res = await this.$wallet.getClaimables({})

        this.nft = res.find(n => n.id === this.id)?.nft

        if(!this.nft) {
          this.$router.push({ name: 'sayl-connect_wallet-claimables' }).catch(() => {})
          return
        }

        this.brand = await this.$brands.getBrand({ id: this.nft.saylAppBrand?.id })
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    async initTransfer() {
      try {
        this.loading = true

        const res = await this.$wallet.getToClaimNft({})

        this.nft = res.find(n => n.id === this.id)?.nft

        if(!this.nft) {
          this.$router.push({ name: 'sayl-connect_wallet-claimables' }).catch(() => {})
          return
        }

        this.brand = await this.$brands.getBrand({ id: this.nft.saylAppBrand?.id })
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    async initView() {
      try {
        this.loading = true
        this.isExternal = false

        await this.findNft()

        if(this.nft) return

        await this.$wallet.getWallet()

        await this.$externalWallet.getWallets()

        if(this.$externalWallet.wallets?.length) {
          for(let i = 0; i < this.$externalWallet.wallets.length; i++) {
            await this.$wallet.getWallet(false, this.$externalWallet.wallets[i].id)
          }
        }

        await this.findNft()
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    async onClaimAccept() {
      try {
        this.actionLoader = true

        await this.$wallet.claim({ id: this.$route.params.id })

        this.$notification({ title: this.$t('conn3ct-wallet.claim_success_title') })

        this.$router.push({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {})
      } catch(e) {
        $console.error(e)
      } finally {
        this.actionLoader = false
      }
    },

    async onTransfer() {
      this.errors = {}

      if(!this.destination || this.destination === '') {
        this.errors.destination = [this.$t('conn3ct-wallet.transfer_nft_destination_required')]
        return
      }

      if(!this.isValidDestination) {
        this.errors.destination = [this.$t('conn3ct-wallet.transfer_nft_invalid_destination')]
        return
      }

      try {
        this.isTransfering = true

        await this.$wallet.transferNft({
          nftId: this.nft?.saylNftId,
          tokenId: this.nft?.tokenId,
          destination: this.destination
        })

        this.$notification({
          title: this.$t('conn3ct-wallet.nft_tranfer_request_success_title'),
          message: this.$t('conn3ct-wallet.nft_tranfer_request_success_description'),
          type: 'success'
        })

        this.showTransferModal = false
        this.$router.push({ name: 'sayl-connect_wallet-wallet', params: { id: 'sayl' }, query: { refresh: true }})
      } catch(e) {
        this.handleErrors(e)
      } finally {
        this.isTransfering = false
      }
    },

    async onTransferAccept() {
      try {
        this.actionLoader = true

        await this.$wallet.claimTransferNft({ transferId: this.$route.params.id })

        this.$notification({ title: this.$t('conn3ct-wallet.transfer_success_title') })

        this.$router.push({ name: 'sayl-connect_wallet-claimables' })
      } catch(e) {
        $console.error(e)
      } finally {
        this.actionLoader = false
      }
    },

    perkRestrictions(perk) {
      let restrictions = []

      if(perk.hasTimeRestriction) {
        restrictions.push(this.$t('conn3ct-wallet.perk_restriction_message', { from: perk.from.toLocaleString(), to: perk.to.toLocaleString() }))
      }

      if(perk.hasTotalLimit && perk.hasFrequencyLimit) {
        restrictions.push(this.$t('conn3ct-wallet.perk_total_frequency_message', { total: perk.totalLimit, frequency: perk.frequencyLimit, unit: perk.frequencyUnit }))
      } else if(perk.hasTotalLimit && !perk.hasFrequencyLimit) {
        restrictions.push(this.$t('conn3ct-wallet.perk_total_message', { total: perk.totalLimit }))
      } else if(!perk.hasTotalLimit && perk.hasFrequencyLimit) {
        restrictions.push(this.$t('conn3ct-wallet.perk_frequency_message', { frequency: perk.frequencyLimit, unit: perk.frequencyUnit }))
      }

      return restrictions.join(', ')
    },
  },

  mounted() {
    this.errors = {}

    if(this.mode === 'view') {
      this.initView()
    } else if(this.mode === 'claim') {
      this.initClaim()
    } else if(this.mode === 'transfer') {
      this.initTransfer()
    }

  },
}
</script>

<template>
  <layout-entity
    class="module-wallet nft"
    :key="hasBanner"
    :loading="loading"
  >
    <template
      v-if="hasBanner"
      v-slot:banner
    >
      <ui-illustration
        :alt="brand.banner.alt.all"
        :src="brand.banner.url.all"
        :type="brand.banner.type"
      />
    </template>

    <ui-card
      class="nft__card"
      :title="nftName"
      :subtitle="nftSubtitle"
    >
      <template v-slot:illustration>
        <ui-illustration
          :alt="nftName"
          fit="contain"
          :src="nftImageUrl"
          :type="nftImageType"
        />
      </template>
    </ui-card>

    <actions-button
      v-if="messages.unreadsCount && mode === 'view'"
      @click="goToMessages"
    >{{ $t('conn3ct-wallet.nft_card_go_to_messages', { count: messages.unreadsCount }) }}</actions-button>

    <actions-button
      v-if="vouchers.length && mode === 'view'"
      @click="goToVouchers"
    >{{ $t('conn3ct-wallet.nft_card_go_to_vouchers', { count: vouchers.length }) }}</actions-button>

    <actions-button
      v-if="!isExternal && mode === 'view'"
      @click="showTransferModal = true"
      :appearance="$pepper.Appearance.PRIMARY"
    >{{ $t('conn3ct-wallet.transfer_action') }}</actions-button>

    <actions-button
      v-if="mode === 'transfer'"
      @click="onTransferAccept"
      :appearance="$pepper.Appearance.PRIMARY"
      :loading="actionLoader"
    >{{ $t('conn3ct-wallet.transfer_accept_action') }}</actions-button>

    <actions-button
      v-if="mode === 'claim'"
      @click="onClaimAccept"
      :appearance="$pepper.Appearance.PRIMARY"
      :loading="actionLoader"
    >{{ $t('conn3ct-wallet.claim_accept_action') }}</actions-button>

    <template v-slot:informations>
      <ui-panel
        v-if="hasDescription"
        class="nft__description"
      >
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.nft_description_title') }}</h4>
        </template>

        <div class="body">
          <p v-html="nftDescription"></p>
        </div>
      </ui-panel>

      <ui-panel
        v-if="brand && brand.name"
        class="nft__brand"
      >
        <template v-slot:header>
          <div class="nft__brand-header">
            <img
              v-if="hasLogo"
              class="nft__brand-logo"
              :src="brand.logo.url.all"
              :alt="brand.logo.alt.all"
            />

            <h4 class="title">{{ brand.name }}</h4>
          </div>
        </template>

        <div
          v-if="brand.description"
          class="body"
        >
          <p v-html="brand.description"></p>
        </div>
      </ui-panel>

      <ui-panel class="nft__info">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.nft_info_title') }}</h4>
        </template>

        <div class="body">
          <p class="nft__info-row">
            <span class="nft__info-label">{{ $t('conn3ct-wallet.nft_info_chain') }}</span>
            <span class="nft__info-value">{{ nftChain }}</span>
          </p>

          <p class="nft__info-row">
            <span class="nft__info-label">{{ $t('conn3ct-wallet.nft_info_token_address') }}</span>
            <a
              class="nft__info-value link"
              target="_blank"
              :href="nftExplorerLink"
            >{{ address }}</a>
          </p>

          <p
            v-if="mode !== 'claim'"
            class="nft__info-row"
          >
            <span class="nft__info-label">{{ $t('conn3ct-wallet.nft_info_id') }}</span>
            <span class="nft__info-value">{{ nftTokenId }}</span>
          </p>
        </div>
      </ui-panel>

      <ui-panel
        v-if="hasAdvantages && mode === 'view'"
        class="nft__advantages"
      >
        <div class="nft__advantages-tabs">
          <h4
            v-if="web3Vouchers.length"
            @click="selectedAdvantageTab = 'vouchers'"
            class="nft__advantages-tab vouchers-tab"
            :class="{
              '-is-selected': selectedAdvantageTab === 'vouchers',
            }"
          >
            {{ $t('conn3ct-wallet.nft_vouchers_tab_title') }}
            <span class="nft__advantages-tab-label">{{ web3Vouchers.length }}</span>
          </h4>

          <h4
          v-if="perks.length"
            @click="selectedAdvantageTab = 'perks'"
            class="nft__advantages-tab perks-tab"
            :class="{
              '-is-selected': selectedAdvantageTab === 'perks',
            }"
          >
            {{ $t('conn3ct-wallet.nft_perks_tab_title') }}
            <span class="nft__advantages-tab-label">{{ perks.length }}</span>
          </h4>
        </div>

        <div class="nft__advantages-list">
          <template v-if="selectedAdvantageTab === 'vouchers'">
            <div
              v-for="voucher in web3Vouchers"
              class="nft__advantage advantage-voucher"
              :key="voucher.id"
            >
              <h5 class="nft__advantage-title">{{ translate(voucher.name) }}</h5>

              <p
                v-if="voucher.lastGenerationDate"
                class="nft__advantage-info last-generation-date"
              >{{ $t('conn3ct-wallet.web3_voucher_last_generation', { date: voucher.lastGenerationDate.toLocaleString() }) }}</p>

              <p
                v-if="voucher.nextDueDate"
                class="nft__advantage-info next-generation-date"
              >{{ $t('conn3ct-wallet.web3_voucher_next_generation', { date: voucher.nextDueDate.toLocaleString() }) }}</p>
            </div>
          </template>

          <template v-if="selectedAdvantageTab === 'perks'">
            <div
              v-for="perk in perks"
              class="nft__advantage advantage-perk"
              :key="perk.id"
            >
              <h5 class="nft__advantage-title">{{ perk.perk.name }}</h5>

              <span class="nft__advantage-info restriction">{{ perkRestrictions(perk) }}</span>

              <div class="nft__advantage-meta">
                <div
                  v-if="perk.perk.clientMessage"
                  class="nft__advantage-meta-value description"
                >
                  <span class="nft__advantage-meta-value-title">{{ $t('conn3ct-wallet.perk_description_title') }}</span>

                  <div
                    v-html="perk.perk.clientMessage"
                    class="nft__advantage-meta-value-field"
                  ></div>
                </div>

                <div
                  v-if="perk.perk.walletMessage"
                  class="nft__advantage-meta-value wallet-message"
                >
                  <span class="nft__advantage-meta-value-title">{{ $t('conn3ct-wallet.hidden_message_title') }}</span>

                  <div
                    v-html="perk.perk.walletMessage"
                    class="nft__advantage-meta-value-field"
                  ></div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </ui-panel>
    </template>

    <popins-modal
      class="transfer-modal"
      :closable="false"
      position="middle-center"
      :size="$pepper.Size.S"
      :visible="showTransferModal"
    >
      <template v-slot:header>
        <div class="transfer-modal__header">
          <h2 class="transfer-modal__title">{{ $t('conn3ct-wallet.transfer_nft_title') }}</h2>

          <button
            @click="showTransferModal = false"
            class="transfer-modal__close"
          >
            <icon-cross />
          </button>
        </div>
      </template>

      <forms-input
        v-model="destination"
        class="modal-transfer-nft__destination-field"
        :placeholder="$t('conn3ct-wallet.nft_transfer_destination_placeholder')"
        :errors="errors['destination']"
      >{{ $t('conn3ct-wallet.nft_transfer_destination_label') }}</forms-input>

      <template v-slot:footer>
        <div class="row">
          <actions-button
            @click="showTransferModal = false"
            :disabled="isTransfering"
          >{{ $t('conn3ct-wallet.cancel_nft_transfer_action') }}</actions-button>

          <actions-button
            @click="onTransfer"
            :appearance="$pepper.Appearance.PRIMARY"
            :loading="isTransfering"
            :disabled="isTransfering"
          >{{ $t('conn3ct-wallet.nft_transfer_action') }}</actions-button>
        </div>
      </template>
    </popins-modal>
  </layout-entity>
</template>
